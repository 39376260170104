/* eslint-disable no-underscore-dangle */
import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CLUB_PROP_TYPES from '../../lib/prop-types/club';
import { getLoggedInUser } from '../../store/membership/selectors';
import { setAdvertsSetup } from '../../store/page-info/actions';

import {
  isLoggedIn as getIsLoggedIn,
  hasCheckedLoginState as getHasCheckedLoginState,
} from '../../store/auth/selectors';

const AdTrackingController = ({
  club,
  hasCheckedLoginState,
  setAdvertsSetup: updateAdvertsSetup,
  isLoggedIn,
}) => {
  // if the club has includeAdverts turned off..
  if (club && !club.includeAdverts) {
    return null;
  }

  const [adsRequested, setAdsRequested] = useState(false);

  useEffect(() => {
    // We are sending off some user specific data to the ad server
    // isLoggedIn is a value sent from the node server, if it is false
    // no one is logged in and we can request the ads.
    // if isLoggedIn is true we need to wait until the hasCheckedLoginState
    // value is true, that way we are guaranteed to have a user and will be able
    // to send all the correct values to the ads server
    if (!isLoggedIn || (isLoggedIn && hasCheckedLoginState && !adsRequested)) {
      setAdsRequested(true);

      return function cleanup() {
        updateAdvertsSetup(false);
      };
    }

    return function cleanup() {};
  }, [isLoggedIn, hasCheckedLoginState]);

  return null;
};

AdTrackingController.propTypes = {
  club: CLUB_PROP_TYPES.isRequired,
  //  pageType: PropTypes.string.isRequired,
  setAdvertsSetup: PropTypes.func.isRequired,
  user: PropTypes.shape({}),
  hasCheckedLoginState: PropTypes.bool.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
};

AdTrackingController.defaultProps = {
  user: undefined,
};

const mapStateToProps = (state) => ({
  hasCheckedLoginState: getHasCheckedLoginState(state),
  user: getLoggedInUser(state),
  isLoggedIn: getIsLoggedIn(state),
});

export default connect(mapStateToProps, { setAdvertsSetup })(AdTrackingController);
